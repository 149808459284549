import { createApp } from "vue"
import App from "@/App.vue"
import { router } from "@/router"
import { store } from "@/store"
import { createPinia } from "pinia"
import ElementPlus from "element-plus"
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import "element-plus/dist/index.css"
import vuetify from "@/plugins/vuetify"
import * as Sentry from "@sentry/vue"

import "flag-icons/css/flag-icons.min.css"
import "v-phone-input/dist/v-phone-input.css"
import { createVPhoneInput } from "v-phone-input"
import { VAutocomplete } from "vuetify/components"

import { PATHS_WITHOUT_AUTH_REGEX } from "@/constants/core"
import AppDefaultLayout from "@/views/AppDefaultLayout.vue"
import AppLoader from "@/components/global/AppLoader.vue"
import AppSnackbar from "@/components/global/AppSnackbar.vue"
import { useAuthStore } from "@/store/core/auth"
import { datadogRum } from "@datadog/browser-rum"

const vPhoneInput = createVPhoneInput({
    countryIconMode: "svg",
    enableSearchingCountry: true
})

const arePartnerPagesReadOnly = window.partner_pages_read_only

const pinia = createPinia()

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component("AppDefaultLayout", AppDefaultLayout)
app.component("VAutocomplete", VAutocomplete)
app.component("AppLoader", AppLoader)
app.component("AppSnackbar", AppSnackbar)

app.config.globalProperties.API_BACKEND_URL = import.meta.env.VITE_API_BACKEND_URL

app.use(vPhoneInput)
app.use(ElementPlus)
app.use(vuetify)
app.use(store)
app.use(pinia)
app.use(router)

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.MODE,
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
        replaysOnErrorSampleRate: 1.0
    })
}

if (import.meta.env.VITE_DATADOG_CLIENT_TOKEN_RUM) {
    datadogRum.init({
        applicationId: "f9d7c5c0-aea3-4687-b874-fac5a151ae59",
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN_RUM,
        site: "datadoghq.eu",
        service: "admin",
        env: import.meta.env.MODE,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        traceSampleRate: 5,
        trackResources: true,
        trackLongTasks: true,
        traceContextInjection: "sampled",
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [
            {
                match: import.meta.env.VITE_API_BACKEND_URL,
                propagatorTypes: ["tracecontext"]
            }
        ]
    })
}

const authStore = useAuthStore()
if (!PATHS_WITHOUT_AUTH_REGEX.test(window.location.pathname)) {
    authStore.getMyAdminUser().catch(() => {
        authStore.logout()
    })
}

app.mount("#app")

export { arePartnerPagesReadOnly }
