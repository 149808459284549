import amplitude from "amplitude-js"

const AMPLITUDE_API_KEY =
    process.env.NODE_ENV === "prod" ? "0291ee9a502847de554aa805cd87eabb" : "eac7df95d305160a71fbe41c0406aeeb"

class AmplitudeWrapper {
    client: any
    init(adminUser: any) {
        if (import.meta.env.DEV) return
        this.client = amplitude.getInstance()
        this.client.init(AMPLITUDE_API_KEY, adminUser.id)

        const userProperties = {
            adminUser: adminUser.login,
            country: adminUser.country,
            carpool_app: adminUser.carpool_app
        }
        this.client.setUserProperties(userProperties)
    }

    logEvent(eventName: any, eventProperties?: any) {
        if (import.meta.env.DEV) return
        this.client.logEvent(eventName, eventProperties)
    }
}

export default new AmplitudeWrapper()
